/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import { Helmet } from "react-helmet";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Author page sections
import Footer from "layouts/sections/footer/Footer";

import "./community-managed.css";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/community-managed.jpg";
import MKButton from "components/MKButton";

function CommunityManaged() {
  return (
    <>
      <Helmet>
          <title>Community Managed - The Community Has Your Back</title>
          <meta property="og:description" content="This site will always take influence and direction from the community on Twitter (X). The person(s) who manage this site do not consider themselves the owners, they are the caretakers of the site." />
          <meta property="og:title" content="Community Managed - The Community Has Your Back" />
          <meta property="og:url" content="https://thecommunityhasyourback.com/community-managed" />
          <meta property="og:image" content={"https://www.thecommunityhasyourback.com" + bgImage} />
          <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://x.com",
          label: "Go To Twitter",
          color: "info",
        }}
      />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "top",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          className="content-main"
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <div className="content">
          <h2 className="center header">Community Managed Site</h2>
          <p>
            This site will always take influence and direction from the community on Twitter (X).
            The person(s) who manage this site do not consider themselves the owners, they are the caretakers of the site. 
          </p>
          <h4>Our vision is that this site is the collective voice and opinion of the community.</h4>
          <p>
            We will continue to maintain this site and work with the community until Twitter (X) is no longer under attack. 
            Given the current state of the world, we don&apos;t see this happening anytime soon and we are prepared to be here for the long haul.
          </p>
          <p>
            The MVP of this site is relatively simple but we have big dreams on what we want to do here to extend our reach and 
            demonstrate to the entire world that the community wants and demands a voice.  The age of censorship is going to end through
            the education and awareness of the masses.  We are here to help with that.
          </p>
          <MKButton
              className="main-button"
              variant="gradient"
              color="info"
              size="large"
              component="a"
              href="/request-changes"
            >
              How To Contribute
            </MKButton>
          </div>
        </Card>
        <Footer />
      </MKBox>
    </>
  );
}

export default CommunityManaged;
