/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import { Helmet } from "react-helmet";

// Author page sections
import Footer from "layouts/sections/footer/Footer";

import "./boycott.css";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/no-boycotts.jpg";

function Boycotts() {
  return (
    <><Helmet>
          <title>Boycotting is bad - The Community Has Your Back</title>
          <meta property="og:description" content="Boycotting it is not a long-term solution because it does not change opinions and is not sustainable." />
          <meta property="og:title" content="Boycotting is bad - The Community Has Your Back" />
          <meta property="og:url" content="https://thecommunityhasyourback.com/boycotts" />
          <meta property="og:image" content={"https://www.thecommunityhasyourback.com" + bgImage} />
          <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://x.com",
          label: "Go To Twitter",
          color: "info",
        }}
      />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          className="content-main"
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <div className="content">
          <h2 className="center header">Why Boycotting is not a long-term solution</h2>
          <p>
            I know this sounds crazy because it did change the behavior of companies like Anheiser Bush and it is true that this
            can be an effective strategy. However, it is not a long-term solution. The reason is that it does not change opinions and is not sustainable.
          </p>
          <p>
            What you are really fighting for is to change the behavior of the companies that you are boycotting. The best way to do
            this is to engage with them and let them know that you are not happy with their behavior.  There are many means to do this
            and we have outlined them on this site.  We will continue to update and outline new ways to change company behaviors.
          </p>
          <p>
            We are so passionate about Twitter/X because we believe that Free Speech is the tool to normalize our society and
            not be such a divided country.  Changing behavior is an outcome of changing an opinion and frankly the more I exercise conversation and
            debate, the more I realize that I am wrong about many things.  I am not afraid to admit that I am wrong and I am not afraid
            to have my opinions challenged.
          </p>
          <p>
            Don&apos;t take the easy way out and just boycott becuase it is destructive and toxic.  You are just practicing the old 
            adage of an &ldquo;eye for eye&ldquo; which will leave the whole world blind.
          </p>
          <h3>
            2 Wrongs Don&apos;t Make Right
          </h3>
          <p>My neice who is an amazing and intelligent peron is going to college at Kent University.  She tells me
            about how the students there are always attempting to shut down opinions which do not match their own.  This happens
            to be a very left leaning college so the opinions that are being shut down are conservative.  
          </p>
          <p>I have no illusions that conservatives and republicans would also employ the same means of censorship if they could.</p>
          <p>Here is the biggest problem with this, these colleges are NOT teaching kids to have open and real conversations, they 
            are teaching them that if you do not like what someone is saying, you should shut them down.
          </p>
          <p>If they don&apos;t like someones opinion, the best way to change a persons opinion and behavoir is through conversations. 
            They NEED to be supporting those they don&apos;t agree with so that they can have a real and true conversation and if they 
            actually want to change their minds.
          </p>
          <p>These schools have lost their minds and are teaching extremely toxic behaviors.  Boycotting is also similiarly toxic and 
            is not a long-term solution.  It is a short-term solution that can be used to change behaviors but it is not sustainable.
          </p>
          </div>
        </Card>
        <Footer />
      </MKBox>
    </>
  );
}

export default Boycotts;
