/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import "./footer.css";

const date = new Date().getFullYear();

function Footer() {
  return (
    <MKBox component="footer" py={6}>
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            lg={6}
            textAlign={{ xs: "left", lg: "left" }}
            mr="auto"
            mb={{ xs: 3, lg: 0 }}
          >
            <MKTypography variant="h6" textTransform="uppercase" mb={{ xs: 2, lg: 3 }}>
              The Community Has Your Back
            </MKTypography>
            <Stack
              component="ul"
              direction="row"
              flexWrap="wrap"
              spacing={6}
              justifyContent={{  lg: "flex-start" }}
              pl={0}
              mb={3}
              sx={{ listStyle: "none" }}
            ><div />
              <MKBox component="li" className="footerMnuLi">
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  opacity={0.8}
                  component={Link}
                  href="/"
                  rel="noreferrer"
                >
                  Home
                </MKTypography>
              </MKBox>
              <MKBox component="li" className="footerMnuLi">
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  opacity={0.8}
                  rel="noreferrer"
                >
                 <b>Twitter is Under Attack</b>
                </MKTypography>
                <br />
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  opacity={0.8}
                  component={Link}
                  href="/who-and-why"
                  rel="noreferrer"
                >
                  Who and why?
                </MKTypography><br />
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  opacity={0.8}
                  component={Link}
                  href="/how-to-help"
                  rel="noreferrer"
                >
                  How can you help?
                </MKTypography><br />
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  opacity={0.8}
                  component={Link}
                  href="/fight-back"
                  rel="noreferrer"
                >
                  Censor the Propaganda
                </MKTypography>
              </MKBox>
              <MKBox component="li" className="footerMnuLi">
              <MKTypography
                  variant="button"
                  fontWeight="regular"
                  opacity={0.8}
                  rel="noreferrer"
                >
                 <b>About This Site</b>
                </MKTypography>
                <br />
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  opacity={0.8}
                  component={Link}
                  href="/community-managed"
                  rel="noreferrer"
                >
                  Community Owned
                </MKTypography>
                <br />
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  opacity={0.8}
                  component={Link}
                  href="/boycotts"
                  rel="noreferrer"
                > Boycotting is Bad
                </MKTypography> <br />
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  opacity={0.8}
                  component={Link}
                  href="/request-changes"
                  rel="noreferrer"
                > Suggest Changes
                </MKTypography>
              </MKBox>
            </Stack>
            <MKTypography variant="button" opacity={0.8}>
             Copyright &copy; {date}{" "} thecommunityhasyourback.com
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} ml="auto" textAlign={{ xs: "center", lg: "right" }}>
            <MKTypography variant="body1" fontWeight="bold" mb={6} sx={{ fontSize: "1.125rem" }}>
            Twitter is one of the few platforms that actually allows free speech and is under attack for this.
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Footer;
