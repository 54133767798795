/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// Pages
import WhoWhy from "layouts/pages/landing-pages/who-why";
import Boycotts from "layouts/pages/landing-pages/boycotts";
import HowToHelp from "pages/LandingPages/HowToHelp";
import FightBack from "pages/LandingPages/FightBack";
import RequestChanges from "pages/LandingPages/RequestChanges";
import CommunityManaged from "pages/LandingPages/CommunityManaged";

const routes = [
  {
    name: "twitter under attack",
    icon: <Icon>error_outline</Icon>,
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "twitter under attack",
        collapse: [
          {
            name: "who and why?",
            route: "/who-and-why",
            component: <WhoWhy />,
          },
          {
            name: "How can you help Twitter?",
            route: "/how-to-help",
            component: <HowToHelp />,
          },
          {
            name: "Fight back (The big picture)",
            route: "/fight-back",
            component: <FightBack />,
          },
        ],
      },
    ],
  },
  {
    name: "About This Site",
    icon: <Icon>difference</Icon>,
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "This Site",
        collapse: [
          {
            name: "Community Managed",
            route: "/community-managed",
            component: <CommunityManaged />,
          },
        ],
      },
      {
        name: "philosophy",
        collapse: [
          {
            name: "boycotting is bad",
            route: "/boycotts",
            component: <Boycotts />,
          },
        ],
      },
      {
        name: "content management",
        collapse: [
          {
            name: "how to suggest a change",
            route: "/request-changes",
            component: <RequestChanges />,
          },
        ],
      },
    ],
  },
];

export default routes;
