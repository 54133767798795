/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
//import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { hydrate, render } from "react-dom";
import App from "App";

const container = document.getElementById("root");

if (container.hasChildNodes()) {
  hydrate( <BrowserRouter>
         <App />
       </BrowserRouter>, container);
} else {
  render( <BrowserRouter>
         <App />
       </BrowserRouter>, container);
}
