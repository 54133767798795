/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Author page sections
import Footer from "layouts/sections/footer/Footer";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import { Helmet } from "react-helmet";

import "./howtohelp.css";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/how-to-help-x.jpg";
import MKButton from "components/MKButton";



function HowToHelp() {
  return (
    <><Helmet>
          <title>How to help Twitter(x) - The Community Has Your Back</title>
          <meta property="og:description" content="Twitter(X) is one of the last true Free Speech platforms left on the internet." />
          <meta property="og:title" content="How to help Twitter(X) - The Community Has Your Back" />
          <meta property="og:url" content="https://thecommunityhasyourback.com/how-to-help" />
          <meta property="og:image" content={"https://www.thecommunityhasyourback.com" + bgImage} />
          <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
    <a name="top"></a>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://x.com",
          label: "Go To Twitter",
          color: "info",
        }}
      />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
       
        <Card
        className="content-main"
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <div className="content">
          <h2 className="center header">How you can help<span className="sm-hide"> Twitter (X)</span></h2>
          <p>
            We do not have to sit on the sidelines and watch Twitter come under attack, we can actually make a big difference.
            Not all of the solutions will cost you anything but your time!
          </p>

          <Card className="card-content">
            <div className="section-header">
              <h3>Why Help?</h3>
            </div>
            <p>
              Twitter(X) is one of the last true <b>Free Speech</b> platforms left on the internet.  
              It is under attack by the <b>mainstream media</b> and <b>big tech</b> companies that are trying to censor the platform.
              <br /><br />
            Free Speech allows people to express their thoughts and ideas, even if they&apos;re unpopular or controversial. This can lead to lively debates and discussions, which can help us learn from one another and grow as a society.
            <br /><br />
            If people are free to criticize their government and hold them accountable, it can help prevent corruption and abuse of power. It&apos;s like having a watchdog that keeps an eye on the powers that be, making sure they&apos;re playing by the rules.
            <br /><br />
            If people are free to express themselves without fear of censorship or punishment, they&apos;re more likely to come up with new and exciting ideas that can benefit us all.
            </p> <Grid>
              <Grid container spacing={3}>
                <Grid item xs={6} md={6} className="center">
                  <MKButton
                    variant="gradient"
                    color="info"
                    size="large" 
                    href="#free">Free Way To Help</MKButton>
                </Grid>
                <Grid item xs={6} md={6} className="center">
                  <MKButton
                    variant="gradient"
                    color="info"
                    size="large"
                    href="#cheap">Cheap Ways to Help</MKButton>
                </Grid>
              </Grid>
            </Grid>
        
          </Card>

          <Divider />
          <a name="free"></a>
          <div className="w1 right">
            <MKButton variant="text" className="right" color="dark" href="#top">Back to Top</MKButton>
          </div>
          <Card className="card-content">
            <div className="section-header">
              <h3>Help Twitter (For Free!!)</h3>
            </div>
            <p>If you are new to Twitter then get involved; if you are not new to Twitter then help out by spreading the word.
             
              We have outlined some of the best ways to help below.   If you have any other ideas, please let us know!

              By doing any or all of the activities below, you are creating activity, engagment and hype around Twitter(X). The more activity, the more people will be exposed to the platform and the more people will join. This is the most powerful thing that you can do to stop the attacks on Twitter(X)
            </p>
              <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <DefaultInfoCard
                      count={0}
                      title="Get an Account"
                      description="If you do not have an account, get one now!  Learn how to use it and everything it offers."
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DefaultInfoCard
                      count={0}
                      title="Follow Interesting People and Topics"
                      description="The more people and topics you follow, the better your feeds and content gets for you."
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
                  <Grid item xs={12} md={6}>
                    <DefaultInfoCard
                      count={0}
                      title="Watch Videos and Read Articles"
                      description="You will be exposed to new unbiased content!  Be sure to like content that you find valuable."
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DefaultInfoCard
                      count={0}
                      title="Comment, Like, and Retweet"
                      description="Become part of the community town hall!  Your tweet, comment, and likes make a difference and promotes further activity."
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
                  <Grid item xs={12} md={6}>
                    <DefaultInfoCard
                      count={0}
                      title="Rate Community Notes"
                      description="Be sure to rate community notes when they pop up! The more feedback they get, the better the become."
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DefaultInfoCard
                      count={0}
                      title="Download the mobile App"
                      description="Get the app on your phone!  It is a great way to stay connected and engaged with the community."
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
                  <Grid item xs={12} md={6}>
                    <DefaultInfoCard
                      count={0}
                      title="Share Content Accross Platforms"
                      description="You can easily share tweets on other social media platforms!  Use this feature to help spread content and exposure."
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DefaultInfoCard
                      count={0}
                      title="Become a Community Notes Contributor"
                      description="Add to the self governing community that helps keep this product safe."
                    />
                  </Grid>
                </Grid>
              </Grid>
          </Card>

          <Divider />
          <a name="cheap"></a>
          <div className="w1 right">
            <MKButton variant="text" className="right" color="dark" href="#top">Back to Top</MKButton>
          </div>
          <Card className="card-content">
            <div className="section-header">
              <h3>Help Twitter (<span className="sm-hide">For a little bit of </span>$$)</h3>
            </div>
            <p>
              Twitter(X) has so much to offer and additional services/support that you can take advantage of for as little as a couple of Starbucks Coffees per month. The more people that take advantage of these services, the more money Twitter(X) has to fight the attacks and improve the platform.
            </p>
        <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <DefaultInfoCard
                count={0}
                title="TWITTER BLUE"
                description="This subscription service offers exclusive features such as the ability to undo a tweet, read articles ad-free on popular sites, upload longer videos, and more."
              />
              <MKButton
              className="button-info"
              href="https://help.twitter.com/en/using-x/x-premium"
              target="_blank"
              variant="gradient"
              size="small"
              color="info"
              >X Premium Details</MKButton>
            </Grid>
            <Grid item xs={12} md={6}>
              <DefaultInfoCard
                count={0}
                title="ADVERTISING"
                description="Advertising on Twitter is a great way to reach a large and engaged audience. With over 330 million monthly active users, Twitter provides an excellent platform to promote your brand or product."
              />
              <MKButton
              className="button-info"
              href="https://business.twitter.com/en/advertising.html"
              target="_blank"
              variant="gradient"
              size="small"
              color="info"
              >Twitter for Business Details</MKButton>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
            <Grid item xs={12} md={6}>
              <DefaultInfoCard
                count={0}
                title="PROMOTION"
                description="You can pay to promote your tweets, accounts, or trends to reach a larger audience. The cost varies based on factors like targeting and duration."
              />
                <MKButton
              className="button-info"
              href="https://help.twitter.com/en/managing-your-account/increase-x-reach"
              target="_blank"
              variant="gradient"
              size="small"
              color="info"
              >X Promotions Details</MKButton>
            </Grid>
            <Grid item xs={12} md={6}>
              <DefaultInfoCard
                count={0}
                title="PROFESSIONAL ACCOUNT"
                description="Twitter offers a free Professional Account option, but there is also a paid version that provides access to additional features like advanced analytics and customer support."
              />
              <MKButton
              className="button-info"
              href="https://business.twitter.com/en/help/account-setup/professional-accounts.html"
              target="_blank"
              variant="gradient"
              size="small"
              color="info"
              >Professional Account Details</MKButton>
            </Grid>
          </Grid>
        
        </Grid>
       </Card>
          </div>
        </Card>
        <Footer />
      </MKBox>
    </>
  );
}



export default HowToHelp;
