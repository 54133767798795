/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
//import CardContent from "@mui/material/CardContent";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import { Helmet } from "react-helmet";

// Author page sections
import Footer from "layouts/sections/footer/Footer";

import "./attacking.css";

// Routes
import routes from "routes";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import Typography from '@mui/material/Typography';
// Images
import bgImage from "assets/images/who-and-why-is-attacking-twitter.png.jpg";
import Divider from "@mui/material/Divider";

function WhoIsAttacking() {
  return (
    <>
    <Helmet>
      <title>Who is attacking Twitter - The Community Has Your Back</title>
      <meta property="og:description" content="Twitter no longer censors based on political points of view or financially driven narratives which means these narratives and opinions are much more difficult to control by centralized entities." />
      <meta property="og:title" content="Who is attacking Twitter - The Community Has Your Back" />
      <meta property="og:url" content="https://thecommunityhasyourback.com/who-and-why" />
      <meta property="og:image" content={"https://www.thecommunityhasyourback.com" + bgImage} />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
    <a name="top"></a>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://x.com",
          label: "Go To Twitter",
          color: "info",
        }}
      />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "top",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
        className="card-content content-main"
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <div className="content">
          <h2 className="center h2header header">Who is attacking <span className="sm-hide">Twitter (X) </span>and Why?</h2>
          <Card>
            <div className="center">
              <MKButton variant="text" color="dark" href="#timeline">Timeline</MKButton>
              <MKButton variant="text" color="dark" href="#post-elon">Post-Elon Changes</MKButton>
              <MKButton variant="text" color="dark" href="#advertisers">Advertisers<span className="sm-hide"> Pausing</span></MKButton>
              <MKButton variant="text" color="dark" href="#adl">Lawsuits</MKButton>
          </div>
          </Card>
          <p>
            This is a complex question to answer because there is not a single definitive entity responcible for the attacks
            However, these collective attacks are engineered to pressure advertisers to stop advertising on Twitter.  We will not be making
            any blanket statements here rather laying out an undeniable series of events that have occured.  You can make up your
            own mind and do your research to determine your own truth.
          </p>
          
          <div className="w1 right">
            <MKButton variant="text" className="right" color="dark" href="#top">Back to Top</MKButton>
          </div>
          <a name="timeline"></a>
            <Card className="card-content no-spacing">
                <div className="section-header">
                  <h3>Timeline of events</h3>
                </div>
            <Timeline position="alternate">
            <TimelineItem className="no-spacing">
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                Pre-Elon Twitter
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot>
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                  Highly Censored
                </Typography>
                <Typography className="timeline-text">-Censorship heavily focused on conservatives.<br />-Media loved Twitter.</Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                variant="body2"
                color="text.secondary"
              >
                Elon Buys Twitter
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot/>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                  Censorship starts to ease
                </Typography>
                <Typography className="right timeline-text">Politically banned accounts reinstated-<br />Media starts attacks on Twitter-</Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                Twitter Files Released
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot>
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                  Proved content moderation has room for improvement.
                </Typography>
                <Typography className="timeline-text">-Balancing free speech w/ political movements<br />-Government involvement in censorship</Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                variant="body2"
                color="text.secondary"
              >
                Elon reforms censorship model
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot/>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                  Staff who disagree with censorship model leave
                </Typography>
                <Typography className="right timeline-text">Biased staff removed-<br />Content Moderation is now unbiased-<br />Media attacks increased-</Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                Twitter Continues to Grow
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot>
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                  As Twitter Grows, Attacks Grow. (as of Dec 2024)
                </Typography>
                <Typography className="timeline-text">-Since Dec. 2022, userbase has grown by 43%<br />-Pressure to Pause Advertsing Grows</Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                variant="body2"
                color="text.secondary"
              >
                Advertising Pauses Increase
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot/>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                  Advertisers Pause Increases (as of Dec 2024)
                </Typography>
                <Typography className="right timeline-text">30% of advertisers pause-<br />Lawsuits start against orgs like ADL-<br />ADL Lawsuite is important, read below-</Typography>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
          </Card>

          <Divider variant="middle" />

          <div className="w1 right">
            <MKButton variant="text" className="right" color="dark" href="#top">Back to Top</MKButton>
          </div>
          <a name="post-elon"></a>
          <Card className="card-content">
            <div className="section-header">
              <h3>Post Elon Changes</h3>
            </div>
            <p className="card-content">
            <h4>What Changed in a Post-Elon Twitter?</h4>
              <p>
                Twitter no longer censors based on political points of view or financially driven narratives
                which means these narratives and opinions are much more difficult to control by centralized entities.
                <br /><br />
                The reality of this situation is that Elon has made Twitter better in almost everyway 
                and has a huge vision for the future.
              </p>
              <p>
                <b>High level list of changes:</b><br />
                <ul>
                  <li> Politically Banned Accounts Reinstated</li>
                  <li> Biased Staff Left or were Removed</li>
                  <li> Content Moderation Council Created</li>
                  <li> Discontinued Government, Media, and Political Influence on Content Moderation</li>
                  <li> Content Moderation is now stronger now, its just not biased</li>
                  <li> Rebuilt their income model</li>
                  <li> Introduced Grok AI</li>
                  <li> The best is yet to come...</li>
                </ul>
              </p> 
              <p>
                <b>False Narratives Pushed to Influence Advertisers:</b><br />
                <ul>
                  <li> Hate Speech Increased</li>
                  <li> Misinformation Increased</li>
                  <li> Musk is a bad leader</li>
                </ul>
              </p>
              <b>Think about this:</b> If the content moderation alogrythms did not change and infact have only grown in effectiveness then how can these media claims be correct? 
              This does not seem truthful because they
              did not seem to have these claims before Elon took over.
              What they are really saying is that there are conflicting point of views on Twitter now which is called <b>&quot;Free Speech&quot;</b>.  <br /><br /> I don&apos;t know of any 
              &quot;Bad Leaders&quot; who can grow a platform by 43% under such an unfair and baised environment.
            </p>
          </Card>
          <Divider variant="middle" />

          <div className="w1 right">
            <MKButton variant="text" className="right" color="dark" href="#top">Back to Top</MKButton>
          </div>
          <a name="advertisers"></a>
            <Card className="card-content">
              <div className="section-header">
                <h3>The Advertisers are Pausing</h3>
              </div>
              <p  className="card-content">
                <>To put into context how the biased Narratives that are being pushed are impacting Twitter, we have composed a list of advertisers and timelines who have paused their 
                marketing campaigns on Twitter.  This is not a full list nor does it represent their current advertising status, rather this is as of Dec. 2024.<br /><br />
                <b>*Note: No one is pausing due to the ineffectiveness of the Twitter Advertising Platform.</b></><br />
                <p><b>November 2022:</b><br />
                Several major advertisers, including General Motors, Volkswagen, and Mondelez International, temporarily paused their advertising on Twitter following Elon Musk&apos;s acquisition of the platform.
                Omnicom Media Group, one of the world&apos;s biggest advertising firms, recommended its clients pause spending on Twitter, citing concerns about the platform&apos;s direction under Musk&apos;s leadership.</p>

                <p><b>December 2022:</b><br />
                Apple, Paramount Global, Disney, and other companies also pulled their ads from Twitter due to concerns about brand safety and the proliferation of hate speech and misinformation on the platform.</p>

                <p><b>January 2023:</b><br />
                More than half of Twitter&apos;s top 1,000 advertisers in September were no longer spending on the platform, according to data provided by a digital marketing analysis firm.
                IBM, Comcast, and other companies joined the list of advertisers pausing their spending on Twitter due to concerns about the platform&apos;s direction and content moderation policies.</p>

                <p><b>February 2023:</b><br />
                The number of advertisers pausing their spending on Twitter continued to grow, with reports indicating that the platform&apos;s advertising revenue had declined significantly since Elon Musk&apos;s acquisition.</p>

                <p><b>March 2023:</b>
                A coalition of civil rights activists, noting that Musk &quot;has lied about everything,&quot; put pressure on the remaining advertisers to pause their spending on the platform.</p>

                <p><b>April 2023:</b>
                Elon Musk announced a new incentive program to encourage advertisers to return to the platform, offering generous matching funds for incremental spending. However, many advertisers remained hesitant due to ongoing concerns about brand safety and the platform&apos;s future.</p>

                <p><b>May 2023:</b>
                Elon Musk publicly criticized Apple and other companies for their decision to pause advertising on Twitter, but this did not appear to have a significant impact on their decisions.</p>

                <p><b>June 2023:</b>
                A group of advertisers, led by the Stop the Deal coalition, called for a boycott of Twitter, citing concerns about the platform&apos;s direction under Elon Musk&apos;s leadership. This led to even more advertisers pausing their spending on the platform.</p>

                <p><b>July 2023:</b>
                Twitter&apos;s advertising revenue continued to decline, with some estimates suggesting that the platform had lost over 50% of its top advertisers since Elon Musk&apos;s acquisition.</p>

                <p><b>August 2023:</b>
                Elon Musk announced that he was considering taking Twitter private in order to address the concerns of advertisers and other stakeholders. However, this plan faced significant opposition from shareholders and other parties.</p>

                <p><b>September 2023:</b>
                The Stop the Deal coalition continued to pressure advertisers to pause their spending on Twitter, and many companies remained hesitant to return to the platform.</p>

                <p><b>October 2023:</b>
                Elon Musk announced a new plan to address the concerns of advertisers and other stakeholders, including new content moderation policies and a renewed focus on brand safety. However, many advertisers remained skeptical, and it remains to be seen whether this will be enough to bring them back to the platform.</p>

                <p>As of <b>December 10, 2023</b>, Twitter&apos;s advertising revenue continues to be significantly impacted by the ongoing advertiser boycott. The platform&apos;s future remains uncertain as it faces challenges in regaining the trust of advertisers and users alike.</p>
              </p>
            </Card>
          <Divider variant="middle" />

          <div className="w1 right">
            <MKButton variant="text" className="right" color="dark" href="#top">Back to Top</MKButton>
          </div>
          <a name="adl"></a>
          <Card className="card-content">
            <div className="section-header">
              <h3>The Lawsuites</h3>
            </div>
          <p className="card-content">
            Elon has been forced to file lawsuits against several organizations who have been responsible for the loss of revenue for Twitter.  
            <p>
            <b>Lawsuites Twitter has had to file</b>
            <ul>
            <li>The X vs. <b>Media Matters</b> case: In November 2023, X filed a lawsuit against Media Matters for allegedly driving away advertisers by spreading misinformation about the platform&apos;s content moderation policies. This lawsuit has caused quite a stir, with some arguing that it&apos;s an attempt to silence critics and others claiming it&apos;s a legitimate defense of X&apos;s reputation.</li>
            <li>The X vs. <b>Anti-Defamation League (ADL)</b> case: Elon Musk threatened to sue the ADL for allegedly defaming X and causing advertisers to leave the platform. This came after the ADL accused X of not doing enough to combat hate speech and misinformation. The case is still pending, and it remains to be seen whether it will actually go to court or not.</li>
            <li>The X vs. <b>Center for Countering Digital Hate (CCDH)</b> case: X filed a lawsuit against the CCDH in August 2023, claiming that the organization had cost the company &quot;tens of millions&quot; in advertising revenue and other expenses. The lawsuit alleges that the CCDH accessed data to show that hateful content was able to remain and circulate on X. The case is ongoing, and it&apos;s unclear what the outcome will be.</li>
            </ul>
            </p>
          <p>
            <b>These companies are playing dirty:</b> <br /> ADL posted that Twitter is putting advertisers ads next to content that conflicts with their brand/views. 
            &nbsp;<b>What Twitter claims really happened:</b> ADL manipulated their aglorithms to make it look like this was happening and then posted it on their site to pressure advertisers to stop advertising on Twitter.<br />
            <br />Twitter says that they can prove the manipulated the alogrythms and that they can prove that ADL is responsible for the loss of billions of dollars in revenue for Twitter.
          </p>
          <p>This is just an example of how these companies are trying to re-take the influence they used to have on this platform.  By removing their funding, they think that they can influence Twitter
            to play ball by their rules or force them to close. 
          </p>   </p>     </Card>
          </div>
  
        </Card>
        <Footer />
      </MKBox>
    </>
  );
}

export default WhoIsAttacking;
