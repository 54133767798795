/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import { Helmet } from "react-helmet";

// Author page sections
import Footer from "layouts/sections/footer/Footer";
import Divider from "@mui/material/Divider";

import "./requestchanges.css";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/request-content-change.jpg";

function RequestChanges() {
  return (
    <><Helmet>
          <title>Suggest Changes - The Community Has Your Back</title>
          <meta property="og:description" content="We want your help! We consider this to be a community site and are implementing a strategy to ensure that this site is the voice of the collective!" />
          <meta property="og:title" content="Suggest Changes - The Community Has Your Back" />
          <meta property="og:url" content="https://thecommunityhasyourback.com/request-changes" />
          <meta property="og:image" content={"https://www.thecommunityhasyourback.com" + bgImage} />
          <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://x.com",
          label: "Go To Twitter",
          color: "info",
        }}
      />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
       
        <Card
          className="content-main"
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <div className="content">
          <h2 className="center header">How to Suggest Changes</h2>
          <p>
            <b>We want your help!</b> We consider this to be a community site and are implementing a strategy to ensure that this site is the voice of the collective!
          </p>
          <p>
            We are using Twitter(X) as the platform to suggest changes and will adhear to community notes as they apply to content on this site.
          </p>
          <h4>How to Request a Change:</h4>
          <p>
            <ul>
              <li> Identify the content you want to change, remove OR add. Then either copy a segment of the text and/or take screenshot.</li>
              <li>Go to Twitter and post a tweet with the hashtags (<b>#thecommunityhasyourback</b> <b>#XSiteChange</b>) and include the requested changes</li>
              <li>If the community agrees then we will make the changes.  We will be posting a &quot;Change log&quot; section to this site where we will be referencing all contributions/tweets.</li>
            </ul>

          </p>
         
          <Divider></Divider>
          <h4>We are working on easier ways to request changes!</h4>
          <p>We will help you form the tweets automatically when some new tools we are working on that uses Twitters 
            existing developer tools.
          </p>
        
          </div>
        </Card>
        <Footer />
      </MKBox>
    </>
  );
}

export default RequestChanges;
