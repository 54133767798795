/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Kit 2 React components
import MKBox from "components/MKBox";
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import { Helmet } from "react-helmet";

// Author page sections
import Footer from "layouts/sections/footer/Footer";

import "./fightback.css";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/censor-the-censorers.jpg";
import spiral from "assets/images/death-spiral.png";
import blocknews from "assets/images/news-platform-feedback.jpg";
import video1 from "assets/images/video-feedback-1.jpg";
import video2 from "assets/images/video-feedback-2.jpg";
import newsmenu from "assets/images/find-news-menu.jpg";
import tutorials from "assets/images/video-tutorials.png";

function FightBack() {
  return (
    <>
      <Helmet>
          <title>Censor the Propaganda - The Community Has Your Back</title>
          <meta property="og:description" content="No matter your views and opinions, you should not be subject to biased, false, or misleading propaganda." />
          <meta property="og:title" content="Censor the Propaganda - The Community Has Your Back" />
          <meta property="og:url" content="https://thecommunityhasyourback.com/fight-back" />
          <meta property="og:image" content={"https://www.thecommunityhasyourback.com" + bgImage} />
          <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://x.com",
          label: "Go To Twitter",
          color: "info",
        }}
      />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "top",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          className="content-main"
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <div className="content">
          <h2 className="center header"><span className="sm-hide">Fight back: </span>Censor the Propaganda</h2>
          <p>
            We want to educate the public on how to hold these media outlets accountable for their biased and unfair propaganda. 
            The only way they will change is when their actions have meaingful consequences. 
          </p>
          <h4 className="center small-header">The propaganda death spiral.</h4>
          <img src={spiral} alt="Creat the propaganda death spiral" className="imgw1" />
          <p>
          <Grid container spacing={2}>
            <Grid item md={1} xs={2}>
              <div className="number-circle">1</div>
            </Grid>
            <Grid item md={11} xs={10}>
              <b>Propaganda is created and pushed by the media.</b>  Their reach is massive and their narratives are not genuine, rather they are typically
              purchased and pushed by these companies.  Dont worry! We are
              going to teach you how to fix this.
            </Grid>
            <Grid item md={1} xs={2}>
              <div className="number-circle">2</div>
            </Grid>
            <Grid item md={11} xs={10}>
              <b>Platforms semi-biased algorthyms.</b>  Their algorthyms are not as biased as you think, by default they are going to feed
              you the bad/biased propaganda until you start giving it feedback.
            </Grid>
            <Grid item md={1} xs={2}>
              <div className="number-circle">3</div>
            </Grid>
            <Grid item md={11} xs={10}>
              <b>Recognizing Biased Content.</b>  Teach yourself how to identify malicious/biased content that comes in the form of 
              misleading messages, out of context narratives, false statemetns, and predictive programming.
            </Grid>
            <Grid item md={1} xs={2}>
              <div className="number-circle">4</div>
            </Grid>
            <Grid item md={11} xs={10}>
              <b>Learn how to Block these biased Firms.</b>  We will teach you how to block these firms from delivering this content to you.
              We will also show you ways you can make the public more aware of their bad business practices.
            </Grid>
            <Grid item md={1} xs={2}>
              <div className="number-circle">5</div>
            </Grid>
            <Grid item md={11} xs={10}>
              <b>Platforms algorthyms start to work for you.</b> Continue to block these biased companies and their content, you will quickly
              have an environment that is safe for you.
            </Grid>
            <Grid item md={1} xs={2}>
              <div className="number-circle">6</div>
            </Grid>
            <Grid item md={11} xs={10}>
              <b>Limit the Reach of these biased companies.</b> When a company releases content, the platform will give them stats on each
              article.  One such statistic is how many people permanently blocked their company as an outcome of that content.  This means
              they can never reach out to you again on this platform.  This is accountability at its highest level for these propaganda firms 
              because you are their product and audience.  This is how you change behavior.
            </Grid>
          </Grid>
          </p>
          <Divider />
          <h4>The basics of blocking propaganda firms and making a safe environment for you.</h4>
          <p>
             No matter your views and opinions, you should not be subject to biased/false/misleading propaganda. We are working
             on a video series that will teach you how to identify and block these firms.  In the meantime, here are some basics.
          </p>

          <Divider />
          <Card className="card-content">
            <CardContent>
              <div className="section-header"><h3>News Platforms and App Widgets</h3></div>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <b>News and Widgets.</b>  Almost all news sites and app widgets that you see have a mechanism to give the respective
                  platform feedback on the content you are seeing.  You can typically trigger this by clicking on the three dots.<br />
                  <br />This is an example of a menu that pops up when you click on the three dots.  You can see that you can give feedback
                  so the algorthyms can learn what you like and dont like. 
                  <br /><br />
                  The more you trian the algorthyms, the more they will work for you. Out of the box, they are going to feed you the bad/biased content.
                </Grid>
                <Grid item md={6} xs={12}>
                  <img src={newsmenu} className="w1" alt="Find the menu for more options" />
                  <img src={blocknews} className="w1" alt="Block the news" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

            <Divider />
            <Card className="card-content">
              <CardContent>
                <div className="section-header"><h3>Video Platforms</h3></div>
                <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                 This gets a lot more complex and has a robust number of ways you can support good content and block
                  bad content.  <br /><br /><b>The Basics:</b> Trigger the Feedback menu, you can typically trigger this by clicking on the three dots.
                  <br /><br />
                  <b>Extra Enforcement:</b> Be sure to subscribe/like good content and actually let the full video play.  You can also share the video to help extend
                  the reach for this content!.  If you do not agree
                  with content then be sure to dislike it, and if you really dont like it then block the channel.

                </Grid>
                <Grid item md={4} xs={12} className="center">
                  <img src={video1} className="w1" />
                  <img src={video2} className="w1" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Divider />
          <div className="center coming-soon">
            <h3>Video Tutorials Coming Soon</h3>
            We will be producing a series of videos that will teach you how to identify and block these firms.
            We will be releasing these on the X, YouTube, and Rumble platforms.<br />
            <img src={tutorials} />
          </div>
          </div>
        </Card>
        <Footer />
      </MKBox>
    </>
  );
}

export default FightBack;
