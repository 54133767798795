/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

import "./information.css";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    The Community
                    <br />
                    Has Your Back
                  </>
                }
                description="This site's sole purpose is to educate the public on how to support Twitter/X, censor biased media, and curb biased tech alogrythms."
              />
              <RotatingCardBack
                image={bgBack}
                title="Our Philosophy on Change"
                description="The most powerful tool to change behavior is through free speech, support, and conversations with those who do not share your opinion."
                action={{
                  type: "internal",
                  route: "/boycotts",
                  label: "Why not Boycott?",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="content_copy"
                  title="Content Management"
                  description="We will be following tweets tagged with (#thecommunityhasyourback, #XSiteChange) and adjusting our content and messaging accordingly."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="tungsten"
                  title="Awareness"
                  description="The world needs to see who is pushing their ideology through immoral means in an effort to limit your ability to engage in free speech."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="question_answer"
                  title="Free Speach is our Goal"
                  description="This is not a political site. We are not here to promote any political party or agenda. We are here to promote free speach."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="cancel"
                  title="We are NOT promoting boycotts"
                  description="We are not asking you to boycott companies/services rather we want to show you how to protect yourself while influencing them to change."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
