/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import "./Counters.css";
import MKButton from "components/MKButton";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

function Counters() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4} className="main-card">
            <DefaultCounterCard
              count={0}
              suffix="+"
              title="Who Is Attacking?"
              description="Who is attacking X (Twitter), how they are attacking, and why?"
            />
            <MKButton
              className="main-button"
              variant="gradient"
              color="info"
              size="large"
              component="a"
              href="/who-and-why"
              sx={{ mb: 2 }}
            >
              Who and Why?
            </MKButton>
          </Grid>
          <Grid item xs={12} md={4} display="flex" className="main-card">
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <div>
              <DefaultCounterCard
                count={0}
                suffix="+"
                title="How to Help X (Twitter)"
                description="Things you can do to help X (Twitter), free and paid actions."
              />
              <MKButton
                className="main-button"
                variant="gradient"
                color="info"
                size="large"
                component="a"
                href="/how-to-help"
                sx={{ mb: 2 }}
              >
                How I Can Help
              </MKButton>
            </div>
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
          <Grid item xs={12} md={4} className="main-card">
            <DefaultCounterCard
              count={0}
              title="Censor the Propaganda"
              description="If we all took these actions, we would criple the propaganda firms!"
            />
            <MKButton
              className="main-button"
              variant="gradient"
              color="info"
              size="large"
              component="a"
              href="/fight-back"
              sx={{ mb: 2 }}
            >
              Fight Back
            </MKButton>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
